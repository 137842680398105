export default {
  en: {
    shopAsGuestMsgLabel1: 'Don’t have a login and want to shop as a guest?',
    shopAsGuestMsgLabel2:
      'Enter your postal code below and start shopping as a guest, right away!',
    postalCodeLabel: 'Postal Code',
    shopAsGuestLabel: 'Shop as guest',
    requiredPostalCode: 'The postal code is required',
    validPostalCode: 'Sorry! The postal code entered is invalid.',
  },
  fr: {
    shopAsGuestMsgLabel1:
      "Vous n'avez pas d'identifiant et souhaitez magasiner en tant que visiteur ?",
    shopAsGuestMsgLabel2:
      'Entrez votre code postal ci-dessous et magasinez en tant que visiteur dès maintenant !',
    postalCodeLabel: 'Code postal',
    shopAsGuestLabel: 'Magasinez en tant que visiteur',
    requiredPostalCode: 'Le code postal est requis.',
    validPostalCode: 'Désolé! Le code postal entré est invalide.',
  },
}
