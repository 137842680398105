export default {
  en: {
    mandatory: 'This field is mandatory.',
    required: 'This field is required.',
  },
  fr: {
    mandatory: 'Ce champ est requis.',
    required: 'Ce champ est requis.',
  },
}
