import PropTypes from 'prop-types'
import classNames from 'classnames'
import Box from '../Box'

/**
 *Name: ListItem
 *Desc: Render an <li> tag.
 * @param {string} className
 * @param {node} children
 */

const ListItem = ({ children, className, ...rest }) => {
  const classes = classNames(className)
  return (
    <Box className={classes} {...rest} as="li">
      {children}
    </Box>
  )
}

// PropType Validation
ListItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

export default ListItem
