import styles from './Table.module.scss'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Text from 'shared/Text'

export function TableHeadingList({
  columns = [],
  noHeaderBackground = false,
  variant,
  variantText,
  color,
  fontWeight,
  className,
}) {
  const tableHeadingClasses = classNames(
    styles.cmnRow,
    {
      [styles.cmnHeader]: !noHeaderBackground,
      [styles[variant]]: variant,
    },
    className
  )
  return (
    <div className={tableHeadingClasses}>
      {columns.map((cell, index) => (
        <TableColumnHeaderCell
          key={`cell-${index}`}
          variantText={variantText}
          color={color}
          fontWeight={fontWeight}
        >
          {cell}
        </TableColumnHeaderCell>
      ))}
    </div>
  )
}

TableHeadingList.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  columns: PropTypes.array,
  fontWeight: PropTypes.string,
  noHeaderBackground: PropTypes.bool,
  variant: PropTypes.string,
  variantText: PropTypes.node,
}

function TableColumnHeaderCell({ variantText, color, fontWeight, children }) {
  return (
    <div>
      <Text variant={variantText} color={color} fontWeight={fontWeight}>
        {children}
      </Text>
    </div>
  )
}

TableColumnHeaderCell.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  fontWeight: PropTypes.string,
  variantText: PropTypes.string,
}
