import {
  STANDING_OFFER_PRODUCTS_TYPE,
  ALL_PRODUCTS_TYPE,
  SEARCH_CLICK_TIME,
} from './SearchConstants'
import {
  CALLING_LOCATION,
  SEARCH_CALLING_LOCATION,
} from '~/components/shared/AdobeAnalytics/AdobeAnalyticsConstants'
import { addQueryString, appendQueryString } from '~/services/utils'
import { getUrl, isHostedInOldEway } from '~/components/shared/EwayRouter'
import {
  BROWSE_PRODUCTS_CATEGORY_ROUTE,
  BROWSE_PRODUCTS_KEYWORD_ROUTE,
} from 'shared/EwayRouter/EwayRouterConstants'
import { getCurrentTimeStamp } from '~/services/utils/dateTimeUtils'
const SEARCH_ROUTES = {
  BROWSE_PRODUCTS_CATEGORY_ROUTE,
  BROWSE_PRODUCTS_KEYWORD_ROUTE,
}

export function canShowAutoSuggest(
  searchResults = [],
  searchValue = '',
  showAutoSuggestion
) {
  return !!searchResults.length && !!searchValue.length && showAutoSuggestion
}

export function buildPwgsUrl(type) {
  return type ? `preSearchFilter=${encodeURIComponent(type)}` : ''
}

export function getSearchUrl(
  word,
  pwgsOption,
  isCategorySearch,
  isBoxType = false,
  isInkAndTonerFinderType = false
) {
  const type = isCategorySearch ? 'CATEGORY' : 'KEYWORD'
  const route = SEARCH_ROUTES[`BROWSE_PRODUCTS_${type}_ROUTE`]
  const searchBaseUrl = getUrl(route)
  const formattedWord = encodeURIComponent(word.trim())
  // On vintage "keyword" is the word used to query
  const queryWord = isHostedInOldEway() ? 'keyword' : 'q'
  const keyword = isCategorySearch
    ? `/${formattedWord}`
    : `?${queryWord}=${formattedWord}`
  const option = pwgsOption?.toString()
  const pwgsValue = option
    ? `preSearchFilter=${encodeURIComponent(option)}`
    : ''
  const urlWithKeyword = `${searchBaseUrl}${keyword}`

  const urlWithType = isBoxType
    ? `${urlWithKeyword}&viewType=box`
    : isInkAndTonerFinderType
    ? `${urlWithKeyword}&viewType=inkAndToner`
    : urlWithKeyword

  const finalUrl = addQueryString(urlWithType, pwgsValue)
  return finalUrl
}

export function getDefaultPwgs(type) {
  return type === '0' ? ALL_PRODUCTS_TYPE : STANDING_OFFER_PRODUCTS_TYPE
}

export function appendSearchCallingLocationToUrl(url) {
  const callingLocation = `${CALLING_LOCATION}=${SEARCH_CALLING_LOCATION.SEARCH_BAR}`
  return appendQueryString(url, callingLocation)
}

export function getSearchUrlWithCallingLocation(word, pwgsOption) {
  const searchUrl = getSearchUrl(word, pwgsOption)
  const finalUrl = appendSearchCallingLocationToUrl(searchUrl)
  const searchClickTime = `${SEARCH_CLICK_TIME}=${getCurrentTimeStamp()}`
  const urlWithSearchTime = appendQueryString(finalUrl, searchClickTime)
  return urlWithSearchTime
}
