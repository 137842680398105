import PropTypes from 'prop-types'
import ScrollBar from 'shared/ScrollBar'
import styles from './Table.module.scss'
import CustomBody from './TableBody/CustomBody'
import classNames from 'classnames'
import TableRow from './TableRow'
import { TableHeadingList } from './TableHeadingList'

/**
 *Name: Table
 *Desc: Table component
 * @param {array} columns
 * @param {array} rows
 * @param {boolean} showToggleIcon
 * @param {boolean} noHeaderBackground
 * @param {string} variant
 * @param {string} fontWeight
 * @param {string} variantText
 * @param {string} color
 * @param {boolean} className
 * @param {string} variantWrapper
 * @param {bool} tableToggle
 * @param {string} scrollClassName
 */

const Table = ({
  rows = [],
  columns = [],
  noHeaderBackground,
  variant,
  fontWeight,
  variantText = 'xsText',
  color,
  ellipsis,
  className,
  scrollClassName,
  autoHideScrollBar,
  customBody = null,
  variantWrapper,
  showBody = true,
}) => {
  const scrollClass = classNames(scrollClassName)

  return (
    <div className={variantWrapper && variantWrapper}>
      <TableHeadingList
        noHeaderBackground={noHeaderBackground}
        columns={columns}
        variant={variant}
        variantText={variantText}
        color={color}
        fontWeight={fontWeight}
        className={className}
      />
      {customBody ? (
        <CustomBody customBody={customBody} styles={styles} />
      ) : showBody ? (
        <ScrollBar autoHide={autoHideScrollBar} className={scrollClass}>
          <div className={styles.bodyContainer} role="rowgroup">
            {rows.map((rowCells, index) => (
              <TableRow
                key={`row-${index}`}
                rowCells={rowCells}
                variant={variant}
                ellipsis={ellipsis}
                className={className}
              />
            ))}
          </div>
        </ScrollBar>
      ) : null}
    </div>
  )
}

// Props Validation
Table.propTypes = {
  customBody: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  columns: PropTypes.array,
  rows: PropTypes.array,
  showBody: PropTypes.bool,
  noHeaderBackground: PropTypes.bool,
  color: PropTypes.string,
  fontWeight: PropTypes.string,
  variant: PropTypes.string,
  variantText: PropTypes.string,
  ellipsis: PropTypes.string,
  className: PropTypes.string,
  autoHideScrollBar: PropTypes.bool,
  variantWrapper: PropTypes.string,
  scrollClassName: PropTypes.string,
}

export default Table
