export const SHOW_USER_LOGIN = 0
export const SHOW_PASSWORD_REMINDER = 1
export const SHOW_USER_ID_REMINDER = 2

export const USER_ID = 'userId'
export const EMAIL_ID = 'email'
export const POSTAL_CODE = 'postalCode'
export const MESSAGE_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
}
export const SIGN_IN_FORM_FIELDS = {
  USER_ID: 'userId',
  PASSWORD: 'password',
}
