import PropTypes from 'prop-types'

const CustomBody = ({ styles, customBody }) => {
  return <div className={styles.bodyContainer}>{customBody}</div>
}

// Props Validation
CustomBody.propTypes = {
  customBody: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  styles: PropTypes.object,
}

export default CustomBody
