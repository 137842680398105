import PropTypes from 'prop-types'
import Button from 'shared/Button'
import { useFormControlled } from '~/components/shared/CustomHooks/useFormControlled'
import Text from 'shared/Text'
import FormField from 'shared/FormControl/FormField'
import { POSTAL_CODE } from '../signInConstants'
import { postalCodeRegex } from '~/services/utils/regexUtils'
import { getHostUrl } from 'services/utils/configUtils'
import messages from './messages'
import styles from './PostalDetail.module.scss'
const HOST_URL = getHostUrl()
/**
 * Name: PostalDetail
 * Decs: Render PostalDetail in sign in component
 * @param {string} language
 */

const maxLength = 6

const PostalDetail = ({ language }) => {
  const { validPostalCode, requiredPostalCode } = messages[language]
  const initValue = {
    [POSTAL_CODE]: {
      value: '',
      required: requiredPostalCode,
      pattern: {
        value: postalCodeRegex,
        message: validPostalCode,
      },
    },
  }
  const { handleChange, handleSubmit, errors, dirties } = useFormControlled(
    initValue,
    language,
    {
      loadWhen: true,
    }
  )

  const formEventHandler = (event) => {
    handleChange(event)
  }

  const handleSubmitForm = (event) => {
    const { isFormValid } = handleSubmit(event)
    isFormValid &&
      window.location.assign(`${HOST_URL}/${language}/Core/Buyers/ShopAsGuest`)
  }

  return (
    <div className={styles.mainBlock}>
      <div className={styles.mt}>
        <Text text={messages[language].shopAsGuestMsgLabel1} />
      </div>
      <div className={styles.mt}>
        <Text text={messages[language].shopAsGuestMsgLabel2} />
      </div>

      <form onSubmit={handleSubmitForm} id="postalDetails" noValidate>
        <div className={styles.padding}>
          <FormField
            fieldLabel={messages[language].postalCodeLabel}
            labelSize="labelXs"
            name={POSTAL_CODE}
            id="postalCode"
            onChange={formEventHandler}
            showError={errors.postalCode && dirties.postalCode}
            errorMessage={errors.postalCode}
            dirties={dirties}
            maxLength={maxLength}
            data-test="postalCodeText"
            aria-label={messages[language].postalCodeLabel}
            aria-required="true"
          />
        </div>
        <Button
          text={messages[language].shopAsGuestLabel}
          variant="secondary"
          data-test="postalCodeBtn"
        />
      </form>
    </div>
  )
}

// Proptype validation
PostalDetail.propTypes = {
  language: PropTypes.string.isRequired,
}

export default PostalDetail
