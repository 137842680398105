import omit from 'lodash/omit'
import { useCallback, useEffect, useRef, useState } from 'react'
import pickBy from 'lodash/pickBy'
import pick from 'lodash/pick'
import {
  getIsFormValid,
  initializeForm,
  runValidationsAllFields,
  runValidationsSingleField,
  setAllDirty,
  SHOW_ERROR_IF_NAME_EMPTY,
  translateForm,
  useFirstRender,
  useFormWarnings,
} from './useFormControlledUtils'
export function useFormControlled(fields, language, { loadWhen = false } = {}) {
  useFormWarnings()
  const firstRender = useFirstRender()
  const initialFormState = useRef()
  const formState = useState(() => {
    const initialState = setInitialFormState(fields)
    return initialState
  })
  const [form, setForm] = formState

  // Allows rendering of forms when some async operation is done or resolved
  // Reloads the whole form once when "loadWhen" is true
  const wasSetOnce = useRef(false)
  useEffect(() => {
    if (loadWhen && !wasSetOnce.current) {
      wasSetOnce.current = true
      const state = setInitialFormState(fields)
      setForm(state)
    }
  }, [loadWhen, fields, setForm])

  // Keep in memory the previous language
  const previousLanguage = useRef()
  // Triggers the translates the form on language change
  const translatedForm = useRef()
  translatedForm.current = translateForm(fields, form)
  useEffect(() => {
    function initTranslateForm() {
      if (!firstRender && language !== previousLanguage.current) {
        setForm(translatedForm.current)
        previousLanguage.current = language
      } else {
        previousLanguage.current = language
      }
    }
    language && initTranslateForm()
  }, [language, setForm, firstRender])

  function setInitialFormState(fields) {
    const initialState = initializeForm(fields)
    initialFormState.current = initialState
    return initialState
  }

  /**
   * handleChange
   * @param {object} event - The event object of the event
   * @param {object} event.target - The event target
   * @param {*} options
   * @returns {object} - Return an object { isValidChange } telling you if the change is valid
   */
  function handleChange({ target }, options) {
    const fieldName = target.name
    const value = target.value

    SHOW_ERROR_IF_NAME_EMPTY(fieldName, target)

    const newChangedState = getChangedState(fieldName, value, options)
    const { errors, values } = newChangedState
    setForm(newChangedState)
    return { isValidChange: !errors[name], errors, values }
  }

  const setValues = useCallback(
    function setValues(updatedValues) {
      const values = {
        ...form.values,
        ...updatedValues,
      }

      const newState = {
        ...form,
        values,
      }
      setForm(newState)
    },
    [form, setForm]
  )

  /**
   * @param {string} fieldName - The field name to enter
   * @param {string} message - The message
   * @param {options} options - Options such as {setDirty: true|false}
   */
  function setError(fieldName, message, { setDirty } = {}) {
    SHOW_ERROR_IF_NAME_EMPTY(fieldName)
    setForm((form) => {
      const dirties = getDirtyValues(setDirty, fieldName)
      const newState = {
        ...form,
        errors: {
          ...form.errors,
          [fieldName]: message,
        },
        dirties,
      }
      return newState
    })
  }
  /**
   * @param {array} fieldNames - The field name to remove error

   */

  function clearErrors(fieldNames = []) {
    const errors = Object.entries(form.errors)
      .filter(([errorField]) => !fieldNames.includes(errorField))
      .reduce((lastMerged, [key, value]) => {
        return { ...lastMerged, [key]: value }
      }, {})
    setForm((form) => {
      const newState = {
        ...form,
        errors,
      }

      return newState
    })
  }

  const addField = useCallback(
    function addField(fieldName, field = {}) {
      SHOW_ERROR_IF_NAME_EMPTY(fieldName)

      setForm((form) => {
        const value = field.value || ''
        const newField = {
          [fieldName]: {
            ...field,
            value,
          },
        }

        const newValue = {
          [fieldName]: value,
        }

        const newState = {
          ...form,
          values: {
            ...form.values,
            ...newValue,
          },
          fields: {
            ...form.fields,
            ...newField,
          },
        }
        return newState
      })
    },
    [setForm]
  )
  const removeField = useCallback(
    function removeField(fieldName) {
      SHOW_ERROR_IF_NAME_EMPTY(fieldName)

      setForm((form) => {
        const newState = {
          ...form,
          fields: omit(form.fields, fieldName),
          values: omit(form.values, fieldName),
        }
        return newState
      })
    },
    [setForm]
  )

  function handleSubmit(e) {
    e?.preventDefault()
    const newSubmitState = getSubmittedState()
    setForm(newSubmitState)
    const { isFormValid, errors } = newSubmitState
    return { isFormValid, errors }
  }

  function getSubmittedState() {
    const allFieldsKeys = Object.keys(form.fields)
    const newErrors = runValidationsAllFields(
      allFieldsKeys,
      form.fields,
      form.values
    )

    const errors = {
      ...form.errors,
      ...newErrors,
    }

    const dirties = setAllDirty(allFieldsKeys)
    const isFormValid = getIsFormValid(newErrors)
    const newSubmitState = {
      ...form,
      errors,
      isFormValid,
      dirties,
      isSubmitted: true,
    }
    return newSubmitState
  }

  function getChangedState(
    fieldName,
    value,
    { setDirty = true, skipValidations = false, setSubmitted = false } = {}
  ) {
    const updatedValues = {
      ...form.values,
      [fieldName]: value,
    }

    if (skipValidations) {
      const newState = {
        ...form,
        values: updatedValues,
      }
      return newState
    }

    const errorMessage = runValidationsSingleField(
      form.fields,
      fieldName,
      value,
      updatedValues
    )
    const error = { [fieldName]: errorMessage }
    const errors = {
      ...form.errors,
      ...error,
    }

    const dirties = getDirtyValues(setDirty, fieldName)
    const changed = getChangedKeys(fieldName, initialFormState, updatedValues)

    const newState = {
      ...form,
      values: updatedValues,
      dirties,
      changed,
      isSubmitted: setSubmitted || false,
      errors,
    }
    return newState
  }

  function getDirtyValues(setDirty, fieldName) {
    return {
      ...form.dirties,
      ...(setDirty && { [fieldName]: true }),
    }
  }

  function getChangedKeys(fieldName, initialForm, updatedValues) {
    const isChanged =
      updatedValues[fieldName] !== initialForm.current.fields[fieldName]?.value
    return {
      ...form.changed,
      ...{ [fieldName]: isChanged },
    }
  }

  function clearForm() {
    setForm({
      ...form,
      values: {},
      errors: {},
      dirties: {},
      changed: {},
    })
  }
  function clearDirties() {
    setForm({
      ...form,
      dirties: {},
    })
  }

  function clearChanged() {
    setForm({
      ...form,
      changed: {},
    })
  }

  function triggerChange(name, value, options) {
    if (!name) {
      throw new Error('name parameter required')
    }
    if (!value === undefined) {
      throw new Error('value parameter required')
    }
    const newChangeState = getChangedState(name, value, options)
    const { errors } = newChangeState
    setForm(newChangeState)
    return { isValidChange: !errors[name], errors }
  }

  function triggerChangeNoDirty(...args) {
    const argsWithNoDirty = [...args, { setDirty: false }]
    return triggerChange(...argsWithNoDirty)
  }

  function triggerSubmit({ logErrors = false } = {}) {
    const newSubmitState = getSubmittedState()
    setForm(newSubmitState)
    const { isFormValid, errors } = newSubmitState
    // Use for debugging
    if (logErrors) {
      // eslint-disable-next-line no-console
      console.log(errors)
    }
    // Find the first error
    const errorEntries = Object.entries(errors).filter(([, value]) => !!value)
    const [firstEntry] = errorEntries
    const [fieldName] = firstEntry || []

    // Focus on the first error found
    if (fieldName) {
      const element = document.querySelector(`[id="${fieldName}"]`)
      element?.focus()
    }

    return { isFormValid, errors }
  }

  const changedKeys = pickBy(form.changed, Boolean)
  const changedValues = pick(form.values, Object.keys(changedKeys))

  return {
    values: form.values,
    errors: form.errors,
    dirties: form.dirties,
    required: form.required,
    changed: form.changed,
    changedValues,
    isSubmitted: form.isSubmitted,
    resetForm: clearForm,
    handleChange,
    handleSubmit,
    setError,
    addField,
    removeField,
    triggerChange,
    triggerChangeNoDirty,
    triggerSubmit,
    setValues,
    resetDirtyValues: clearDirties,
    resetChangedValues: clearChanged,
    clearErrors,
  }
}
