import isRegExp from 'lodash/isRegExp'
import { useEffect, useRef } from 'react'
import { isInteger, isNumeric } from 'services/utils'
import { isTest, isProduction } from '~/config/env/environmentUtils'

export function initializeForm(fields = {}) {
  const values = getValuesFromFields(fields)
  const required = getAllRequiredFields(fields)
  return { fields, values, errors: {}, dirties: {}, changed: {}, required }
}

function getValuesFromFields(fields) {
  const values = Object.entries(fields).map((entry) => {
    const [key, props] = entry
    return [key, props.value]
  })
  return Object.fromEntries(values)
}

export function translateForm(newFields, form) {
  const translatedFields = translateFields(newFields, form)
  const formWithNewFields = {
    ...form,
    fields: {
      ...translatedFields,
    },
  }
  const translatedErrors = translateErrors(form, formWithNewFields)
  const newState = {
    ...formWithNewFields,
    errors: {
      ...form.errors,
      ...translatedErrors,
    },
  }
  return newState
}

function translateFields(newFields, form) {
  const newFieldEntries = Object.keys(newFields).map((field) => {
    const newField = newFields[field]
    const value = {
      ...form.fields[field],
      required: newField?.required,
      pattern: {
        ...form.fields[field]?.pattern,
        message: newFields[field]?.pattern?.message,
      },
    }
    return [field, value]
  })
  const translatedFields = Object.fromEntries(newFieldEntries)
  return translatedFields
}

function translateErrors(form, newForm) {
  const newErrorEntries = Object.entries(form.errors)
    .filter((entry) => {
      const [, value] = entry
      return !!value
    })
    .map((error) => {
      const [fieldName] = error
      const fields = newForm.fields
      const formValues = newForm.values
      const fieldValue = formValues[fieldName]
      const errorMessage = runValidationsSingleField(
        fields,
        fieldName,
        fieldValue,
        formValues
      )
      return [fieldName, errorMessage]
    })
  const newErrors = Object.fromEntries(newErrorEntries)
  return newErrors
}

export function runValidationsAllFields(allFieldKeys, fields, formValues) {
  const errors = allFieldKeys.map((fieldName) => {
    const fieldValue = formValues[fieldName]
    const errorMessage = runValidationsSingleField(
      fields,
      fieldName,
      fieldValue,
      formValues
    )
    return [fieldName, errorMessage]
  })
  return Object.fromEntries(errors)
}

export function runValidationsSingleField(
  fields,
  fieldName,
  value,
  formValues
) {
  const requiredError = handleRequiredForField(fields, fieldName, value)
  const regexError = handleRegexForField(fields, fieldName, value)
  const integerError = handleIntegerForField(fields, fieldName, value)
  const numericError = handleNumericForField(fields, fieldName, value)
  const validateError = handleValidateForField(
    fields,
    fieldName,
    value,
    formValues
  )
  const minimumLengthError = handleMinimumLengthField(fields, fieldName, value)
  const maximumLengthError = handleMaximumLengthField(fields, fieldName, value)

  const errorMessage = controlOrderErrorsShown([
    requiredError[fieldName],
    integerError[fieldName],
    numericError[fieldName],
    regexError[fieldName],
    validateError[fieldName],
    minimumLengthError[fieldName],
    maximumLengthError[fieldName],
  ])
  return errorMessage
}

export function setAllDirty(allFieldsKeys) {
  return Object.fromEntries(allFieldsKeys.map((fieldName) => [fieldName, true]))
}

export function getAllRequiredFields(fields) {
  const values = Object.entries(fields)
    .filter((field) => {
      const [, value] = field
      return !!value.required
    })
    .map((field) => {
      const [key] = field
      return [key, true]
    })
  const requiredFields = Object.fromEntries(values)
  return requiredFields
}

export function getIsFormValid(errors) {
  return !Object.values(errors).filter(Boolean).length
}

export function useFormWarnings() {
  useEffect(() => {
    // only take forms that have an id
    const formsWithIds = Array.from(document.querySelectorAll('form[id]'))
    const formsWithoutIds = Array.from(
      document.querySelectorAll('form:not([id])')
    )

    const ids = formsWithIds.map((element) => element.id).filter(Boolean)

    const hasDuplicate = new Set(ids).size !== ids.length

    formsWithoutIds.forEach((form) => {
      if (isProduction()) {
        return
      }
      // eslint-disable-next-line no-console
      console.warn(`Id is missing for form, all of them should have ids `)
      // eslint-disable-next-line no-console
      console.log({ element: form })
    })

    if (hasDuplicate) {
      WARN_IN_TEST_ENV()
      // eslint-disable-next-line no-console
      console.warn(
        'Forms with the same id was detected, usually ids for forms should be unique'
      )
    }
  }, [])
}

function WARN_IN_TEST_ENV() {
  if (isTest()) {
    // eslint-disable-next-line no-console
    console.warn(
      'Dont forget to use clearJSDOM from __mocks__/test-utils.js by using "beforeEach" in tests run, use manage-addresses.spec as an example'
    )
  }
}

export function useFirstRender() {
  const firstRender = useRef(true)

  useEffect(() => {
    firstRender.current = false
  }, [])

  return firstRender.current
}

export function SHOW_ERROR_IF_NAME_EMPTY(fieldName, target) {
  if (!fieldName) {
    const fieldHtml = target.outerHTML ? target.outerHTML : ''
    const message = target
      ? `Please add a name attribute to othe input field to the field ${fieldHtml}`
      : `"fieldName" is required`
    throw new Error(message)
  }
}

export function controlOrderErrorsShown(errors) {
  // Always start with the required
  // Then if there is more, handle them after
  const errorOrder = errors
  // Remove those that dont have any errors
  const grouped = errorOrder.filter(Boolean)
  // Always take the first one
  const [first] = grouped
  const finalMessage = first?.toString() || ''
  return finalMessage
}

export function handleRequiredForField(fields, fieldName, newValue) {
  const message = fields[fieldName]?.required
  const hasMessageAndEmpty = !!message && !newValue
  const errorField = {
    [fieldName]: hasMessageAndEmpty ? message : '',
  }
  return errorField
}

export function handleIntegerForField(fields, fieldName, newValue) {
  const message = fields[fieldName]?.isInteger
  const hasValueMessageAndNotInteger =
    !!newValue && !!message && !isInteger(newValue)
  const errorField = {
    [fieldName]: hasValueMessageAndNotInteger ? message : '',
  }
  return errorField
}

export function handleNumericForField(fields, fieldName, newValue) {
  const message = fields[fieldName]?.isNumeric
  const hasValueMessageAndNotNumeric =
    !!newValue && !!message && !isNumeric(newValue)
  const errorField = {
    [fieldName]: hasValueMessageAndNotNumeric ? message : '',
  }
  return errorField
}

export function handleRegexForField(fields, fieldName, value) {
  const field = fields[fieldName]
  const hasPattern = !!isRegExp(field?.pattern?.value)
  const stringValue = String(value)
  if (hasPattern) {
    const { value: regex, message } = field.pattern
    const hasValueAndNotMatched = !!stringValue && !stringValue?.match(regex)
    return {
      [fieldName]: hasValueAndNotMatched ? message : '',
    }
  }
  return { [fieldName]: '' }
}

export function handleValidateForField(fields, fieldName, value, formValues) {
  const field = fields[fieldName]
  const hasValidCallback = typeof field?.validate?.callback === 'function'
  if (hasValidCallback) {
    const { callback, message } = field.validate
    const { isValid, message: callbackMessage } = callback(value, formValues)
    return {
      [fieldName]: isValid ? '' : callbackMessage || message,
    }
  }

  return { [fieldName]: '' }
}

export function handleMinimumLengthField(fields, fieldName, value) {
  const field = fields[fieldName]
  const hasMinimumLength = !!value && value.length < field?.minimumLength?.value
  if (hasMinimumLength) {
    const { message } = field.minimumLength
    return {
      [fieldName]: hasMinimumLength ? message : '',
    }
  }
  return { [fieldName]: '' }
}

export function handleMaximumLengthField(fields, fieldName, value) {
  const field = fields[fieldName]
  const hasMaximumLength = !!value && value.length > field?.maximumLength?.value
  if (hasMaximumLength) {
    const { message } = field.maximumLength
    return {
      [fieldName]: hasMaximumLength ? message : '',
    }
  }
  return { [fieldName]: '' }
}
