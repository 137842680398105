import PropTypes from 'prop-types'
import styles from './TableRow.module.scss'

/**
 * Name: TableRow
 * Desc: Render table rows
 * @param {object} rowCells
 * @param {string} variant
 * @param {string} ellipsis
 * @param {boolean} className
 */
const TableRow = ({ rowCells, variant, ellipsis, className }) => {
  const variantClass = variant ? styles[variant] : ''
  const ellipsisClass = ellipsis ? styles[ellipsis] : ''
  const tableClassName = className || ''
  return (
    <div
      className={`${styles.cmnRow} ${variantClass} ${ellipsisClass} ${tableClassName}`}
      data-test="table-component-row"
      role="row"
    >
      {rowCells.map((rowCell, index) => (
        <div role="cell" key={`row-cell-${index}`}>
          {rowCell}
        </div>
      ))}
    </div>
  )
}

TableRow.propTypes = {
  rowCells: PropTypes.array.isRequired,
  variant: PropTypes.string,
  ellipsis: PropTypes.string,
  className: PropTypes.string,
}
export default TableRow
